const { API_KEY } = require('../constants');

// const api = "https://hpm-cloud-api.azurewebsites.net" //"https://hpm-web-platform-api.herokuapp.com" //"http://localhost:4000" //
// const wsApi = "ws://hpm-cloud-api.azurewebsites.net" //"wss://hpm-web-platform-api.herokuapp.com" //"ws://localhost:4000" //

// const api = "http://localhost:4000" //
// const wsApi = "ws://localhost:4000" //

// const api = "https://dev-api.hpmcharge.com" //
// const wsApi = "ws://dev-api.hpmcharge.com" //

const api = "https://api.hpmcharge.com" //"https://hpm-web-platform-api.herokuapp.com" //"http://localhost:4000" //
const wsApi = "ws://api.hpmcharge.com" //"wss://hpm-web-platform-api.herokuapp.com" //"ws://localhost:4000" //

// THESE CONSTANTS BELONG IN THE DATABASE!!!!
const pxBundles = [
    { description: "Avanos diagnostic shoulder 3 nerves - Left", code: "Bundle", codes: [21, 38, 90] },
    { description: "Avanos diagnostic shoulder 3 nerves - Right", code: "Bundle", codes: [87, 25, 153] },
    { description: "Avanos ablation shoulder 3 nerves - Left", code: "Bundle", codes: [65, 67, 67] },
    { description: "Avanos ablation shoulder 3 nerves - Right", code: "Bundle", codes: [154, 67, 67] },
    { description: "2 Level TFESI (64483, 64484)", code: "Bundle", codes: [165, 166]},
    { description: "2 Level TFESI, Right (64483-RT, 64484-RT)", code: "Bundle", codes: [84, 96]},
    { description: "2 Level TFESI, Left (64483-LT, 64484-LT)", code: "Bundle", codes: [91, 92]},
    { description: "2 Level TFESI, Bi-Lateral (64483-50, 64484-50)", code: "Bundle", codes: [31, 285]},
    { description: "2 Level Cerv/Thoracic MBB (64490, 64491)", code: "Bundle", codes: [167, 168]},
    { description: "2 Level Cerv/Thoracic MBB KX (64490-KX, 64491-KX)", code: "Bundle", codes: [181, 185]},
    { description: "2 Level Cerv/Thoracic MBB, Right (64490-RT, 64491-RT)", code: "Bundle", codes: [34, 57]},
    { description: "2 Level Cerv/Thoracic MBB, Right KX (64490-RTKX, 64491-RTKX)", code: "Bundle", codes: [183, 187]},
    { description: "2 Level Cerv/Thoracic MBB, Left (64490-LT, 64491-LT)", code: "Bundle", codes: [29, 36]},
    { description: "2 Level Cerv/Thoracic MBB, Left KX (64490-LTKX, 64491-LTKX)", code: "Bundle", codes: [182, 186]},
    { description: "2 Level Cerv/Thoracic MBB, Bi-Lateral (64490-50, 64491-50)", code: "Bundle", codes: [32, 33]},
    { description: "2 Level Cerv/Thoracic MBB, Bi-Lateral KX (64490-50KX, 64491-50KX)", code: "Bundle", codes: [184, 188]},
    { description: "2 Level Lumb/Sacr MBB (64493, 64494)", code: "Bundle", codes: [170, 171]},
    { description: "2 Level Lumb/Sacr MBB KX (64493-KX, 64494-KX)", code: "Bundle", codes: [189, 193]},
    { description: "2 Level Lumb/Sacr MBB, Right (64493-RT, 64494-RT)", code: "Bundle", codes: [40, 41]},
    { description: "2 Level Lumb/Sacr MBB, Right KX (64493-RTKX, 64494-RTKX)", code: "Bundle", codes: [191, 195]},
    { description: "2 Level Lumb/Sacr MBB, Left (64493-LT, 64494-LT)", code: "Bundle", codes: [37, 42]},
    { description: "2 Level Lumb/Sacr MBB, Left KX (64493-LTKX, 64494-LTKX)", code: "Bundle", codes: [190, 194]},
    { description: "2 Level Lumb/Sacr MBB, Bi-Lateral (64493-50, 64494-50)", code: "Bundle", codes: [39, 44]},
    { description: "2 Level Lumb/Sacr MBB, Bi-Lateral KX (64493-50KX, 64494-50KX)", code: "Bundle", codes: [192, 196]},
    { description: "2 Level Cerv/Thoracic RFA (64633, 64634)", code: "Bundle", codes: [174, 175]},
    { description: "2 Level Cerv/Thoracic RFA, Right (64633-RT, 64634-RT)", code: "Bundle", codes: [56, 59]},
    { description: "2 Level Cerv/Thoracic RFA, Left (64633-LT, 64634-LT)", code: "Bundle", codes: [5, 75]},
    { description: "2 Level Cerv/Thoracic RFA, Bi-Lateral (64633-50, 64634-50)", code: "Bundle", codes: [58, 248]},
    { description: "2 Level Lumb/Sacr RFA (64635, 64636)", code: "Bundle", codes: [176, 177]},
    { description: "2 Level Lumb/Sacr RFA, Right (64635-RT, 64636-RT)", code: "Bundle", codes: [60, 63]},
    { description: "2 Level Lumb/Sacr RFA, Left (64635-LT, 64636-LT)", code: "Bundle", codes: [61, 64]},
    { description: "2 Level Lumb/Sacr RFA, Bi-Lateral (64635-50, 64636-50)", code: "Bundle", codes: [62, 234]},
    // { description: "Trifecta - Bursa, Piriformis, Sciatic Injection (20610, 20552, 64445)", code: "Bundle", codes: [19, 141]},
    { description: "Trifecta - Bursa, Piriformis, Sciatic Injection (20552, 64445)", code: "Bundle", codes: [19, 141]},
    { description: "Trifecta - Bursa, Piriformis, Sciatic Injection, Right (20610-RT, 20552, 64445-RT)", code: "Bundle", codes: [19, 95, 85]},
    { description: "Trifecta - Bursa, Piriformis, Sciatic Injection, Left (20610-LT, 20552, 64445-LT)", code: "Bundle", codes: [19, 6, 26]},
    // { description: "Trifecta - Bursa, Piriformis, Sciatic Injection, Bi-Lateral (20610-50, 20552, 64445-50)", code: "Bundle", codes: [19, 233]}
    { description: "Trifecta - Bursa, Piriformis, Sciatic Injection, Bi-Lateral (20610-50, 20552)", code: "Bundle", codes: [19, 233]}
]
const unitRateExpections = [
    { location: "Powell", code: "24", rate: 0.35 },
    { location: "Powell", code: "11", rate: 0.9 },
    { location: "Powell", code: "25", rate: 0.9 },
    { location: "Powell", code: "38", rate: 0.9 },
    { location: "Powell", code: "3", rate: 1.6 },
    { location: "Powell", code: "33", rate: 0.75 },
    { location: "Powell", code: "36", rate: 0.75 },
    { location: "Powell", code: "57", rate: 0.75 },
    { location: "Powell", code: "35", rate: 0.75 },
    { location: "Powell", code: "77", rate: 0.75 },
    { location: "Powell", code: "78", rate: 0.75 },
    { location: "Powell", code: "13", rate: 0.45 },
    { location: "Powell", code: "55", rate: 0.45 },
    { location: "Powell", code: "65", rate: 0.45 },
    { location: "Powell", code: "154", rate: 0.45 },
    { location: "Powell", code: "163", rate: 0.9 },
    { location: "Powell", code: "173", rate: 0.45 }
]

module.exports = {
    api,
    wsApi,
    pxBundles,
    unitRateExpections
}