const { API_KEY } = require('../constants');

const tokenProvider = {
    get token() {
        if (typeof window !== "undefined"){
            return localStorage.getItem(API_KEY);
        } else {
            return '';
        }        
    }
}

module.exports = {
    tokenProvider
}