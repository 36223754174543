/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react'
import { ApolloProvider } from 'react-apollo'
import ApolloClient from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { ApolloLink, concat } from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { api } from './src/env/connection'
import { tokenProvider } from './src/providers/tokenProvider';

const link = new HttpLink({
    uri: api
})

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    if (tokenProvider.token) {
        operation.setContext({
            headers: {
                "Authorization": `Bearer ${tokenProvider.token}`
            }
        });
    }

    return forward(operation);
})

const client = new ApolloClient({
    link: concat(authMiddleware, link),
    cache: new InMemoryCache()
})

export const wrapRootElement = ({ element }) => {
    return (
        <ApolloProvider client={client}>
            {element}
        </ApolloProvider>
    )
}