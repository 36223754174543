const RFA_EQUIPMENT_KEY = "lastRFAEquipmentSelection";
const API_KEY = "API_TOKEN";
const STORED_NOTIFICATIONS = "storedNotifications";
const DISMISSED_NOTIFICATIONS = "dismissedNotifications";
const PowellFlatRate = 25;
const GOOGLE_FORMS_SURVEY = "https://docs.google.com/forms/d/e/1FAIpQLScUYBQG0p3r618msCgXE5X7PXk48xY7SnAMRkBVbIabvPjL4g/viewform?embedded=true";
const SURVEY_KEY = "disableSurveyBanner";

module.exports = {
  RFA_EQUIPMENT_KEY,
  API_KEY,
  STORED_NOTIFICATIONS,
  DISMISSED_NOTIFICATIONS,
  PowellFlatRate,
  GOOGLE_FORMS_SURVEY,
  SURVEY_KEY
}